
  import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
  import { defineComponent } from 'vue';
  import { client } from '@/client';
  import { ElMessage } from 'element-plus';
import { FriendGroupsData } from "@/shared/types/FriendsData";
  
  export default defineComponent({
    name: "group-index",
    setup() {
      return {
        Edit,
        Delete,
        View,
        Search,
      };
    },
    data: () => {
      return {
        keywords: "",
        tableData: [] as FriendGroupsData[],
        multipleSelection: [],
        count: 0,
        cur: 1,
        userid:0,
      };
    },
    computed: {},
    created() {
        let id = this.$route.params.id as string;
        this.userid = parseInt(id);
        this.list();
    },
    methods: {
      //查询
      async list() {
        try {
          let res = await client.callApi("group/List",{cur:this.cur,userid:this.userid});
          if (res.isSucc) {
            let data = res.res;
            this.tableData = [...data.list];
            this.count = data.count;
          }
        } catch (error) {
          console.log(error);
        }
      },
  
      //翻页
      handleCurrentChange(e: number) {
        this.cur = e;
        this.list();
      },
  
      toggleSelection(rows: any[]) {
        let refs:any = this.$refs.multipleTable;
        if (rows) {
          rows.forEach((row: any) => {
            refs.toggleRowSelection(row);
          });
        } else {
          refs.clearSelection();
        }
      },
  
      handleSelectionChange(val: never[]) {
        this.multipleSelection = val;
      },
  
      //编辑
      toEdit(e: { id: any; }) {
        let id = e.id;
        this.$router.push({ name: "group-edit", params: { id: id } });
      },
      groupAdd(){
        this.$router.push({ name: "group-add", params: { id: this.userid } });
      },
      //删除
      async handleDel(e: { id: any; }) {
        let id = e.id;
        try {
          let res = await client.callApi("group/Del",{ids:[id]});
          if (res.isSucc) {
            ElMessage({
              message: "删除成功 ^_^",
              type: "success",
            });
            this.list();
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  });
  